
.container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.6);
    cursor: pointer;
    animation-name: fade-in;
    animation-duration: 0.2s;
    z-index: 90;
}

@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1;}
}

.container.closing {
    animation-name: fade-out;
    animation-duration: 0.2s;
    animation-delay: 0.2s;
    opacity: 1;
}

@keyframes fade-out {
    from { opacity: 1; }
    to { opacity: 0;}
}

.aside {
    position: absolute;
    display: flex;
    flex-direction: column;
    left: 0;
    right: 0;
    width: calc(100% - 60px);
    height: 100%;
    background: #ffffff;
    animation-name: move-out;
    animation-duration: 0.4s;
    z-index: 91;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
}

@keyframes move-out {
    from { transform: translateX(-100%); }
    to { transform: translateX(0); }
}

.container.closing .aside {
    animation-name: move-in;
    animation-duration: 0.3s;
}

@keyframes move-in {
    from { transform: translateX(0); }
    to { transform: translateX(-100%); }
}


.header {
    display: flex;
    align-items: center;
    height: 44px;
    background: var(--color-primary);
}

.close {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 44px;
    width: 44px
}

.heading {
    color: #ffffff;
    font-weight: 700;
    font-size: 1.3rem;
}

.overflow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}

.menu {
    display: flex;
    flex: 1 0 auto;
    flex-direction: column;
    align-self: flex-start;
    width: 100%;
    padding: 0 16px;
}

.menu > a, .menu .item { 
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    text-decoration: none;
    padding: 12px 8px;
    width: 100%;
    border-bottom: 1px #E6E6E5 solid;
    color: #1A1B1D;
    cursor: pointer;
}

.menu .item .label_container {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.menu .item .chevron {
    display: inline-flex;
    margin-left: 10px;
    transition: transform .2s;
}

.menu .item:hover .chevron_open { transform: rotate(180deg); }

.submenu {
    display: none;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 20px;
}

.submenu_open {
    display: flex;
}

.submenu > a:first-child { border-top: 1px #E6E6E5 solid; margin-top:12px; }

.submenu > a {
    text-decoration: none;
    padding: 12px 8px;
    width: 100%;
    border-bottom: 1px #E6E6E5 solid;
    color: #1A1B1D;
    cursor: pointer;
}

.submenu > a:last-child { border: none; padding: 12px 8px 0px 8px; }

.bottommenu {
    display: flex;
    flex-direction: column;
    align-self: flex-end;
    width: 100%;
    padding: 0 16px 14px 16px;
    margin-top: 30px;
}

.bottommenu > a {
    color: #1A1B1D;
    cursor: pointer;
    text-decoration: none;
    padding: 10px 8px;
}