.root {
    color: #FFF;
}

.root a {
    color: inherit;
    text-decoration: none;
}

.root a:hover {
    color: inherit;
}

.root h4 {
    width: 100%;
    padding: 8px 0;
    margin-bottom: 0;
    color: inherit;
}

.nav {
    background: #57004E;
    min-height: 250px;
}

.terms {
    background: #3E0037;
    font-size: 1.2rem;
    text-align: center;
}

.terms a {
    padding: 0 10px;
    border-right: 1px solid #FFF;
}

.terms a:last-child {
    border-right: none;
}

.copyright {
    margin: 0 0 8px 0;
    font-size: 1.4rem;
}

.links {
    border-bottom: 1px solid #3E0037;
}

.links ul {
    margin: 0;
    padding: 16px 0;
    list-style: none;
}

.links li {
    margin: 0 0 16px 0;
}

.links li:last-child {
    margin: 0;
}

.brochure {
    padding: 16px 0;
    text-align: center;
}


@media (min-width: 768px) {

    .nav { padding: 36px 0; }

    .links { border-bottom: none; }

    .brochure { text-align: left; }
}