.product_shown {
    display: flex;
    justify-content: center;
}

.load_more {
    display: flex;
    justify-content: center;
}

