.root { position: relative; height: 100%; padding: 16px; border: 1px solid #E5E5E5; border-radius: 5px; }

.image { position: relative; }

.product_info_container { padding: 15px 0 8px; font-family: 'AvantGardeMedium'; color: var(--primary-color); font-size: 1.4rem; font-weight: 400; overflow: hidden; text-overflow: ellipsis; }
.bottom_wrapper { display: flex; justify-content: space-between; }

.link { text-decoration: none; }

.price { display: flex; align-items: center; font-weight: 700; font-size: 1.2rem; color: #000; margin-top: 3px; }

@media (min-width:960px) {
    .product_info_container { padding: 15px 8px 8px; font-size: 1.8rem; }
    .price { margin-top: 10px; font-size: 1.6rem; }
}