.text {
    margin: 8px 0 16px 0;
}

.filter_button > span {
    display: flex;
    align-items: center;
}

.filter_button > span span:first-child {
    margin-right: 5px;
    font-size: 1.1rem;
    line-height: 1.32rem;
}

@media (min-width: 768px) {
    .filter_button {
        display: none;
    }
}

@media (max-width: 768px) {
    .toolbar {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
    }

    .toolbar > * {
        width: calc(50% - 4px);
    }

    .filter_button > span {
        justify-content: center;
    }
}