.root {
    display: flex;
    margin: 0 -16px;
}

.root img {
    width: 100%;
}

.left { width: 100%; }
.right { display: none; }

@media (min-width:768px) {

    .root { margin: auto; }

    .left,
    .right { width: 50%; }
    .right { display: block; }
}
