.cart {
    background: #F6F4F0;
    border-radius: 5px;
    padding: 24px;
}

.cart > div {
    display: flex;
}

.cart > div > div {
    width: 20%;
    text-align: center;
}

.cart > div > div:first-child {
    width: 60%;
    text-align: left;
}

.cart > div > div:last-child {
    text-align: right;
}

.top_container {
    padding: 0 0 16px;
    border-bottom: 1px solid #E6E6E5;
}

.cart_label {
    font-size: 1.8rem;
    line-height: 2.0rem;
    color: var(--color-primary);
}

.product_price {
    font-family: "AvantGardeBold";
}

.product_container {
    display: flex;
    align-items: center;
    padding: 16px 0 20px;
    position: relative;
}

.product_container:after {
    content: '';
    height: 1px;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: #E6E6E5;
}

.product_name {
    font-size: 1.6rem;
    line-height: 1.6rem;
}

.product_sku {
    margin-top: 8px;
    font-size: 1.0rem;
    line-height: 1.0rem;
}

.subtotal {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-family: "AvantGardeBold";
    padding: 24px 0 0;
}

.subtotal > label:first-child {
    margin-right: 24px;
}