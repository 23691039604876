.root {
    color: inherit;
}

.title {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.icon {
    position: absolute;
    right: 0;
}

.body {
    display: none;
}

.open .body {
    display: block;
}
