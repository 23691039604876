.root {
    display: flex;
    align-items: center;
    font-size: 1.0rem;
    margin-top: 10px;
}

.divider {
    margin: 0 5px;
}

.link {
    text-decoration: none;
    color: #333333;
}

.link:last-child {
    color: var(--color-primary);
}