.root {
    display: flex;
    flex-direction: column;
    margin: 0 0 24px 0;
}

.root a {
    text-decoration: none;
    color: #333333;
    padding: 4px 0px 4px 0px;
    margin: 0 0 4px 0;
} 

.root a span { margin-right: 6px; }

.root a:hover {
    text-decoration: underline;
} 