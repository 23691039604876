.checkout_container {
    margin-top: 30px;
}

.title {
    margin: 0;
}

.title:after {
    content: '';
    height: 1px;
    width: 100%;
    background: #E6E6E5;
    margin: 8px 0 10px;
    display: block;
}

.info {
    margin: 0 0 48px;
}

.support {
    margin-top: 48px;
}

.error { padding: 15px 0; color: #B72C47; }
.success { padding: 15px 0; color: #1AA77E; }
.required { font-size:1.2rem; color: #EE0128; float: right }

.minimum_cart_amount {
    color: #B72C47;
    font-weight: 600;
}