.imageContainer {
    position: relative;
}

.imageContainer img {
    border-radius: 5px;
}

.banner_container {
    position: relative;
}

.banner_link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.hidden {
    position: absolute;
    left: -99999px;
}