.root {
    position: relative;
}

.filter_close {
    transform: rotate(45deg);
    position:relative;
    margin-top: 3px;
}

.filter_top_container {
    background: var(--color-primary);
    color: #FFFFFF;
    display: flex;
    padding: 10px 15px;
    align-items: center;
}

.filter_top_container > span {
    font-size: 1.3rem;
    line-height: 1.56rem;
    margin-left: 17px;
}

@media (max-width: 768px) {
    .root {
        padding: 16px;
    }

    .fade {
        display: flex;
        background: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        width: 100%;
        height: 35px;
        position: absolute;
        top: -35px;
        left: 0;
    }

    .show_products_container {
        background: #FFFFFF;
        padding: 0 16px 16px;
        width: 100%;
        margin: 0 auto;
        position: sticky;
        bottom: 0;
        left: 0;
    }

    .show_products_button {
        width: 100%;
    }
}