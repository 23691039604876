
.field {
    position: relative; 
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    background: #F6F4F0;
    border-radius: 5px;
}

.input {
    width: 100%;
    margin: 0;
    padding: 10px 16px;
    background: #F6F4F0;
    color: #949290;
    border: 0;
    outline: none;
    border-radius: 5px;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.input:-internal-autofill-selected {
    background: #000 !important;
}

.icon {
    margin:0 10px;
    color: #777777;
    cursor: pointer;
}


.input_error,
:global(form.validated) .input:invalid {
    border: 1px solid #FF595C;
    background: #FFE8DD;
}
