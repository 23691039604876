.root {
    margin-top: 48px;
}

.title {
    margin: 0;
}

.title:after {
    content: '';
    height: 1px;
    width: 100%;
    background: #E6E6E5;
    margin: 8px 0 10px;
    display: block;
}

.list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.usp_list {
    display: flex;
    align-items: center;
}

.usp_list:not(:last-child) {
    margin-bottom: 12px;
}

.icon {
    margin-right: 8px;
}