
.field {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
}

.label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.4rem;
}

.checkbox_input { 
    position: absolute;
    opacity: 0;
    left: 6px;
    bottom: 0px;
}

.checkbox {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0;
    width: 12px;
    height: 12px;
    padding: 3px;
    border: 1px solid var(--color-primary);
    background: #ffffff;
    outline: none;
    margin-right: 15px;
    cursor: pointer;
}

.checked {
    display: inline-flex;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    background: var(--color-primary);
}

.checked:after {
    content: '';
    background-image: url('data:image/svg+xml;utf8,<svg width="7" height="6" viewBox="0 0 7 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.2275 0.72749C5.33315 0.626907 5.47374 0.5713 5.61961 0.572404C5.76548 0.573508 5.90521 0.631235 6.00933 0.733406C6.11345 0.835576 6.1738 0.974198 6.17766 1.12002C6.18151 1.26584 6.12857 1.40746 6.03 1.51499L3.0375 5.25749C2.98604 5.31291 2.92393 5.35739 2.8549 5.38827C2.78586 5.41914 2.7113 5.43577 2.63568 5.43717C2.56007 5.43857 2.48495 5.42471 2.41481 5.39641C2.34468 5.36812 2.28097 5.32597 2.2275 5.27249L0.242996 3.28799C0.187731 3.23649 0.143404 3.17439 0.11266 3.10539C0.0819165 3.03639 0.065385 2.96191 0.0640524 2.88638C0.0627198 2.81085 0.0766135 2.73583 0.104904 2.66579C0.133195 2.59575 0.175303 2.53213 0.228718 2.47871C0.282132 2.4253 0.345757 2.38319 0.415798 2.3549C0.485839 2.32661 0.560861 2.31271 0.636388 2.31405C0.711915 2.31538 0.7864 2.33191 0.8554 2.36265C0.924399 2.3934 0.9865 2.43772 1.038 2.49299L2.6085 4.06274L5.21325 0.74399C5.21794 0.738216 5.22294 0.732707 5.22825 0.72749H5.2275Z" fill="white"/></svg>');
    position: absolute;
    width: 6px;
    height: 6px;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}

.input_error,
:global(form.validated) .checkbox_input:invalid + label {
    color: #FF595C;
}