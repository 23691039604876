.list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.list li {
    margin-bottom: 8px;
}

.list li:last-child { margin-bottom: 0; }

.day {
    display: inline-block;
    width: 110px;
}

.bold {
    font-weight: bold;
}

.devider {
    margin: 0 5px;
}