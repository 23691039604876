
.field {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 0 20px 0;
}

.label {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 1.4rem;
}

.radio_input { 
    position: absolute;
    opacity: 0;
    left: 6px;
    bottom: 0px;
}

.radio {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 0;
    width: 12px;
    height: 12px;
    padding: 3px;
    border: 1px solid var(--color-primary);
    background: #ffffff;
    outline: none;
    margin-right: 15px;
    cursor: pointer;
    border-radius: 50%;
}

.checked {
    display: inline-flex;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background: var(--color-primary);
}

.input_error,
:global(form.validated) .radio_input:invalid + label {
    color: #FF595C;
}