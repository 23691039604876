.root {
    vertical-align: middle;
}

.responsive {
    max-width: 100%;
    height: auto;
}

.container {
    position: relative;
    padding: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
}

.container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.product { padding-bottom: 55.55%; }
.product-thumb { padding-bottom: 55.55%; }
.category { padding-bottom: 33.78378378378378%; }
.category-thumb { padding-bottom: 100%; }

.location_detail {
    padding-bottom: 20.66326530612245%; 
} 

.location_detail_mobile { padding-bottom: 55.56%; }