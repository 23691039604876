.root {
    display: flex;
}

.input {
    height: 41px;
    width: 56px;
    margin-right: 8px;
    border-radius: 5px;
    font-size: 1.4rem;
    line-height: 1.68rem;
    color: #333333;
    text-align: center;
    border: 1px solid #E6E6E5;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    -moz-appearance:textfield;
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.add_to_cart_button {
    width: 56px;
    height: 40px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add_to_cart_button > span {
    vertical-align: middle;
    display: table-cell;
}

.add_to_cart_button > span:last-child {
    margin-left: 3px;
}

.disabled {
    cursor: not-allowed;
    opacity: 0.7;
}