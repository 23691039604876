:root {
    --gutter-x: 24px;
    --gutter-y: 24px;
}


.container,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    width: 100%;
    padding-right: calc(var(--gutter-x) * .5); /* 15px; */
    padding-left: calc(var(--gutter-x) * .5); /* 15px; */
    margin-right: auto;
    margin-left: auto;
}

.margins {
    width: calc(100% - var(--gutter-x));
}


.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: calc(var(--gutter-x) * -.5); /* -15px */
    margin-left: calc(var(--gutter-x) * -.5); /* -15px */
}

.row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--gutter-x) * .5); /* 15px; */
    padding-left: calc(var(--gutter-x) * .5); /* 15px; */
    padding-top: calc(var(--gutter-y) * .5);
    padding-bottom: calc(var(--gutter-y) * .5);
}


.col { flex: 1 0 0%; }

.col-1 { flex: 0 0 auto; width: 8.33333333%; }
.col-2 { flex: 0 0 auto; width: 16.66666667%; }
.col-3 { flex: 0 0 auto; width: 25%; }
.col-4 { flex: 0 0 auto; width: 33.33333333%; }
.col-5 { flex: 0 0 auto; width: 41.66666667%; }
.col-6 { flex: 0 0 auto; width: 50%; }
.col-7 { flex: 0 0 auto; width: 58.33333333%; }
.col-8 { flex: 0 0 auto; width: 66.66666667%; }
.col-9 { flex: 0 0 auto; width: 75%; }
.col-10 { flex: 0 0 auto; width: 83.33333333%; }
.col-11 { flex: 0 0 auto; width: 91.66666667%; }
.col-12 { flex: 0 0 auto; width: 100%; }

.order-0 { order: 0; }
.order-1 { order: 1; }
.order-2 { order: 2; }
.order-3 { order: 3; }
.order-4 { order: 4; }
.order-5 { order: 5; }
.order-6 { order: 6; }
.order-7 { order: 7; }
.order-8 { order: 8; }
.order-9 { order: 9; }
.order-10 { order: 10; }
.order-11 { order: 11; }
.order-12 { order: 12; }
.order-first { order: -1; }
.order-last { order: 13; }

.g-0 { --gutter-x: 0px; --gutter-y: 0px; }
.g-1 { --gutter-x: 8px; --gutter-y: 8px; }
.g-2 { --gutter-x: 16px; --gutter-y: 16px; }
.g-3 { --gutter-x: 24px; --gutter-y: 24px; }
.g-4 { --gutter-x: 32px; --gutter-y: 32px; }
.g-5 { --gutter-x: 48px; --gutter-y: 48px; }

.gy-0 { --gutter-y: 0px; }
.gy-1 { --gutter-y: 8px; }
.gy-2 { --gutter-y: 16px; }
.gy-3 { --gutter-y: 24px; }
.gy-4 { --gutter-y: 32px; }
.gy-5 { --gutter-y: 48px; }


@media (min-width: 640px) {
    
    .container-sm { max-width: 640px; }

    .col-sm-1 { flex: 0 0 auto; width: 8.33333333%; }
    .col-sm-2 { flex: 0 0 auto; width: 16.66666667%; }
    .col-sm-3 { flex: 0 0 auto; width: 25%; }
    .col-sm-4 { flex: 0 0 auto; width: 33.33333333%; }
    .col-sm-5 { flex: 0 0 auto; width: 41.66666667%; }
    .col-sm-6 { flex: 0 0 auto; width: 50%; }
    .col-sm-7 { flex: 0 0 auto; width: 58.33333333%; }
    .col-sm-8 { flex: 0 0 auto; width: 66.66666667%; }
    .col-sm-9 { flex: 0 0 auto; width: 75%; }
    .col-sm-10 { flex: 0 0 auto; width: 83.33333333%; }
    .col-sm-11 { flex: 0 0 auto; width: 91.66666667%; }
    .col-sm-12 { flex: 0 0 auto; width: 100%; }

    .order-sm-0 { order: 0; }
    .order-sm-1 { order: 1; }
    .order-sm-2 { order: 2; }
    .order-sm-3 { order: 3; }
    .order-sm-4 { order: 4; }
    .order-sm-5 { order: 5; }
    .order-sm-6 { order: 6; }
    .order-sm-7 { order: 7; }
    .order-sm-8 { order: 8; }
    .order-sm-9 { order: 9; }
    .order-sm-10 { order: 10; }
    .order-sm-11 { order: 11; }
    .order-sm-12 { order: 12; }
    .order-sm-first { order: -1; }
    .order-sm-last { order: 13; }

    .g-sm-0 { --gutter-x: 0px; --gutter-y: 0px; }
    .g-sm-1 { --gutter-x: 8px; --gutter-y: 8px; }
    .g-sm-2 { --gutter-x: 16px; --gutter-y: 16px; }
    .g-sm-3 { --gutter-x: 24px; --gutter-y: 24px; }
    .g-sm-4 { --gutter-x: 32px; --gutter-y: 32px; }
    .g-sm-5 { --gutter-x: 48px; --gutter-y: 48px; }

    .gy-sm-0 { --gutter-y: 0px; }
    .gy-sm-1 { --gutter-y: 8px; }
    .gy-sm-2 { --gutter-y: 16px; }
    .gy-sm-3 { --gutter-y: 24px; }
    .gy-sm-4 { --gutter-y: 32px; }
    .gy-sm-5 { --gutter-y: 48px; }
}


@media (min-width: 768px) {

    .container-md { max-width: 768px; }

    .col-md-1 { flex: 0 0 auto; width: 8.33333333%; }
    .col-md-2 { flex: 0 0 auto; width: 16.66666667%; }
    .col-md-3 { flex: 0 0 auto; width: 25%; }
    .col-md-4 { flex: 0 0 auto; width: 33.33333333%; }
    .col-md-5 { flex: 0 0 auto; width: 41.66666667%; }
    .col-md-6 { flex: 0 0 auto; width: 50%; }
    .col-md-7 { flex: 0 0 auto; width: 58.33333333%; }
    .col-md-8 { flex: 0 0 auto; width: 66.66666667%; }
    .col-md-9 { flex: 0 0 auto; width: 75%; }
    .col-md-10 { flex: 0 0 auto; width: 83.33333333%; }
    .col-md-11 { flex: 0 0 auto; width: 91.66666667%; }
    .col-md-12 { flex: 0 0 auto; width: 100%; }

    .order-md-0 { order: 0; }
    .order-md-1 { order: 1; }
    .order-md-2 { order: 2; }
    .order-md-3 { order: 3; }
    .order-md-4 { order: 4; }
    .order-md-5 { order: 5; }
    .order-md-6 { order: 6; }
    .order-md-7 { order: 7; }
    .order-md-8 { order: 8; }
    .order-md-9 { order: 9; }
    .order-md-10 { order: 10; }
    .order-md-11 { order: 11; }
    .order-md-12 { order: 12; }
    .order-md-first { order: -1; }
    .order-md-last { order: 13; }

    .g-md-0 { --gutter-x: 0px; --gutter-y: 0px; }
    .g-md-1 { --gutter-x: 8px; --gutter-y: 8px; }
    .g-md-2 { --gutter-x: 16px; --gutter-y: 16px; }
    .g-md-3 { --gutter-x: 24px; --gutter-y: 24px; }
    .g-md-4 { --gutter-x: 32px; --gutter-y: 32px; }
    .g-md-5 { --gutter-x: 48px; --gutter-y: 48px; }

    .gy-md-0 { --gutter-y: 0px; }
    .gy-md-1 { --gutter-y: 8px; }
    .gy-md-2 { --gutter-y: 16px; }
    .gy-md-3 { --gutter-y: 24px; }
    .gy-md-4 { --gutter-y: 32px; }
    .gy-md-5 { --gutter-y: 48px; }
}


@media (min-width: 1024px) {
    
    .container-lg { max-width: 1024px; }

    .col-lg-1 { flex: 0 0 auto; width: 8.33333333%; }
    .col-lg-2 { flex: 0 0 auto; width: 16.66666667%; }
    .col-lg-3 { flex: 0 0 auto; width: 25%; }
    .col-lg-4 { flex: 0 0 auto; width: 33.33333333%; }
    .col-lg-5 { flex: 0 0 auto; width: 41.66666667%; }
    .col-lg-6 { flex: 0 0 auto; width: 50%; }
    .col-lg-7 { flex: 0 0 auto; width: 58.33333333%; }
    .col-lg-8 { flex: 0 0 auto; width: 66.66666667%; }
    .col-lg-9 { flex: 0 0 auto; width: 75%; }
    .col-lg-10 { flex: 0 0 auto; width: 83.33333333%; }
    .col-lg-11 { flex: 0 0 auto; width: 91.66666667%; }
    .col-lg-12 { flex: 0 0 auto; width: 100%; }

    .order-lg-0 { order: 0; }
    .order-lg-1 { order: 1; }
    .order-lg-2 { order: 2; }
    .order-lg-3 { order: 3; }
    .order-lg-4 { order: 4; }
    .order-lg-5 { order: 5; }
    .order-lg-6 { order: 6; }
    .order-lg-7 { order: 7; }
    .order-lg-8 { order: 8; }
    .order-lg-9 { order: 9; }
    .order-lg-10 { order: 10; }
    .order-lg-11 { order: 11; }
    .order-lg-12 { order: 12; }
    .order-lg-first { order: -1; }
    .order-lg-last { order: 13; }

    .g-lg-0 { --gutter-x: 0px; --gutter-y: 0px; }
    .g-lg-1 { --gutter-x: 8px; --gutter-y: 8px; }
    .g-lg-2 { --gutter-x: 16px; --gutter-y: 16px; }
    .g-lg-3 { --gutter-x: 24px; --gutter-y: 24px; }
    .g-lg-4 { --gutter-x: 32px; --gutter-y: 32px; }
    .g-lg-5 { --gutter-x: 48px; --gutter-y: 48px; }

    .gy-lg-0 { --gutter-y: 0px; }
    .gy-lg-1 { --gutter-y: 8px; }
    .gy-lg-2 { --gutter-y: 16px; }
    .gy-lg-3 { --gutter-y: 24px; }
    .gy-lg-4 { --gutter-y: 32px; }
    .gy-lg-5 { --gutter-y: 48px; }
}


@media (min-width: 1200px) {
    
    .container-xl { max-width: 1200px; }

    .col-xl-1 { flex: 0 0 auto; width: 8.33333333%; }
    .col-xl-2 { flex: 0 0 auto; width: 16.66666667%; }
    .col-xl-3 { flex: 0 0 auto; width: 25%; }
    .col-xl-4 { flex: 0 0 auto; width: 33.33333333%; }
    .col-xl-5 { flex: 0 0 auto; width: 41.66666667%; }
    .col-xl-6 { flex: 0 0 auto; width: 50%; }
    .col-xl-7 { flex: 0 0 auto; width: 58.33333333%; }
    .col-xl-8 { flex: 0 0 auto; width: 66.66666667%; }
    .col-xl-9 { flex: 0 0 auto; width: 75%; }
    .col-xl-10 { flex: 0 0 auto; width: 83.33333333%; }
    .col-xl-11 { flex: 0 0 auto; width: 91.66666667%; }
    .col-xl-12 { flex: 0 0 auto; width: 100%; }

    .order-xl-0 { order: 0; }
    .order-xl-1 { order: 1; }
    .order-xl-2 { order: 2; }
    .order-xl-3 { order: 3; }
    .order-xl-4 { order: 4; }
    .order-xl-5 { order: 5; }
    .order-xl-6 { order: 6; }
    .order-xl-7 { order: 7; }
    .order-xl-8 { order: 8; }
    .order-xl-9 { order: 9; }
    .order-xl-10 { order: 10; }
    .order-xl-11 { order: 11; }
    .order-xl-12 { order: 12; }
    .order-xl-first { order: -1; }
    .order-xl-last { order: 13; }
    
    .g-xl-0 { --gutter-x: 0px; --gutter-y: 0px; }
    .g-xl-1 { --gutter-x: 8px; --gutter-y: 8px; }
    .g-xl-2 { --gutter-x: 16px; --gutter-y: 16px; }
    .g-xl-3 { --gutter-x: 24px; --gutter-y: 24px; }
    .g-xl-4 { --gutter-x: 32px; --gutter-y: 32px; }
    .g-xl-5 { --gutter-x: 48px; --gutter-y: 48px; }

    .gy-xl-0 { --gutter-y: 0px; }
    .gy-xl-1 { --gutter-y: 8px; }
    .gy-xl-2 { --gutter-y: 16px; }
    .gy-xl-3 { --gutter-y: 24px; }
    .gy-xl-4 { --gutter-y: 32px; }
    .gy-xl-5 { --gutter-y: 48px; }
}
