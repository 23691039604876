.root {
    background: #f6f4f0;
    padding: 20px;
    border-radius: 5px;
}

.product_name {
    margin-bottom: 8px;
}

.sku {
    text-transform: uppercase;
}

.info_bottom {
    display: flex;
    margin-top: 32px;
    align-items: center;
    justify-content: space-between;
}

.price {
    font-size: 2.8rem;
    line-height: 2.8rem;
    font-family: "AvantGardeBold";
}

@media (max-width: 768px) {
    .root {
        border-radius: 0;
        margin-left: -16px;
        margin-right: -16px;
    }
}