
.list_container {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    flex-direction: column;
    background-color: #57004E;
    width: 100%;
    min-height: 105px;
    border-radius: 5px 5px 0px 0px;
    padding: 17px;
    color:#ffffff;
}

.title {
    color:#ffffff;
}

.list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    min-height: 300px;
    border: 1px solid #E6E6E5;
    border-radius: 0px 0px 5px 5px;
    padding: 0px 16px;  
}

.list::-webkit-scrollbar {
    width: 8px;
}

.list::-webkit-scrollbar-track {
    background: #ffffff;
}

.list::-webkit-scrollbar-thumb {
    background: #57004E;
    border: 3px solid #ffffff; 
    border-radius: 20px;
}

.list a {
    color: unset;
    text-decoration: none;
}

.item {
    border-bottom: 1px solid #E6E6E5;
    padding: 16px 8px;
}

.list a:last-child .item { border: none; }

.name {  margin-bottom: 8px; }

.address {
    display: block;
    margin-bottom: 8px;
}

.link {
    display: block;
    color: var(--color-primary);
    text-decoration: underline;
}

.link:hover {
    color: var(--color-primary-darken);
}

.loading {
    margin: 40px 0;
}


@media (min-width: 640px) {

    .list {
        height: 560px;
        overflow-y: auto;   
    }
    
}