.usps {
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: bold;
}

.usps img:first-child { margin: 0 10px 0 0; }
.usps img { margin: 0 10px 0 20px; }

.usps span { display: inline-flex; margin-bottom: 4px; }

.topmenu {
    display: flex;
    align-items: center;
}

.topmenu a {
    text-decoration: none;
    text-transform: uppercase;
    color: #666666;
    margin-right: 24px;
}

.topmenu a:last-child { margin-right: 0px; }

.align_right {
    display: flex;
    justify-content: flex-end;
}