.header {
    width: 100%;
}

.middle_container {
    display: flex;
    justify-content: space-between;
}

.middle {
    display: flex;
    align-items: center;
    height: 44px;
    background: var(--color-primary);
}

.logo_container { 
    display: flex; 
    align-items: center;
    padding: 8px 0;
}

.logo_container a {     
    display: flex; 
}

.logo_container img { height: 28px; width: auto; }

.align_right {
    display: flex;
    justify-content: flex-end;
}

.menu_icon {
    display: flex; 
    align-items: center;
    width: 50px;
}

.card {
    width: 50px;
}

@media (min-width: 768px) {

    .top {
        display: flex;
        align-items: center;
        height: 32px;
        background: #ffffff;
        font-size: 1.1rem;
        color: #57004E;
    }

    .middle {
        display: flex;
        align-items: center;
        height: 72px;
        padding: 16px 0;
    }
    
    .logo_container { 
        justify-content: flex-start;
    }

    .logo_container img { height: auto; }
    
    .payoff { 
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        color: #ffffff; 
        font-size: 1.3rem; 
        line-height: 1.5rem;
        margin-left: 32px;
    }
    
    .bottom {
        display: flex;
        align-items: center;
        height: 40px;
        background: var(--color-primary-darken);
    }
    
    
}