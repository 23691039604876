.root { position: relative; }

.slides { display: flex; overflow-x: auto; overflow-y: hidden; width: 100%; }
.slides { scroll-snap-type: x mandatory; -webkit-overflow-scrolling: touch; scroll-behavior: smooth; scroll-padding: 0 8px; padding: 0 8px; }

.slide { max-width: 44.44444444444444%; flex: 0 0 44.44444444444444%; padding: 8px; }
.slide { scroll-snap-align: start; position: relative; }

.prev,
.next { display: none; position: absolute; top: 0; width: 32px; height: 100%; z-index: 10; }
.prev { left: 0; }
.next { right: 0; }

.prev button,
.next button { position: absolute; top: calc(41% - 16px); display: flex; align-items: center; justify-content: center; margin: 0; padding: 0; width: 32px; height: 32px; background: #fff; box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, .15); border-radius: 50%; border: none; cursor: pointer; }


@media (min-width:768px) {
    
    .slides { scrollbar-color: #57004E #E5E5E5; scrollbar-width: thin; }
    .slides::-webkit-scrollbar { height: 6px; }
    .slides::-webkit-scrollbar-track { background: #E5E5E5; border-radius: 3px; }
    .slides::-webkit-scrollbar-thumb { background: #57004E; border-radius: 3px; border: 1px solid #ffffff; }

    .slide { max-width: 28%; flex: 0 0 28%; }

    .show-1.slide { max-width: 100%; flex: 0 0 100%; }
    .show-2.slide { max-width: 50%; flex: 0 0 50%; }
    .show-3.slide { max-width: 33.333333%; flex: 0 0 33.333333%; }
    .show-4.slide { max-width: 25%; flex: 0 0 25%; }
    .show-5.slide { max-width: 20%; flex: 0 0 20%; }    

    .prev,
    .next { display: block; }
}

@media (min-width:960px) {
    
    .slide { max-width: 16.66666666666667%; flex: 0 0 16.66666666666667%; }
    .count-5 { max-width: 20%; flex: 0 0 20%; }
}

@media (min-width:1280px) {

    .slides { scroll-padding: 0; padding: 0; }
    
    .prev { left: -32px; }
    .next { right: -32px; }

    .prev button,
    .next button { box-shadow: none; }
}
