.imageContainer {
    position: relative;
}

.imageContainer img {
    border-radius: 5px;
}

.banner_container {
    position: relative;
}

.col_container {
    position: relative;
}

.banner_link {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

.homebanner_button {
    position: absolute;
    z-index: 2;
    bottom: 16px;
}

.smallbanner_container {
    margin-top: -8px;
    margin-bottom: -8px;
}

.hidden {
    position: absolute;
    left: -99999px;
}

@media (min-width: 768px) {
    
    .homebanner_button {
        right: 16px;
    }
}

@media (max-width: 768px) {

    .banner_container .imageContainer {
        margin: -8px -16px 0 -16px;
    }

    .banner_container img {
        border-radius: 0;
    }

    .homebanner_button {
        left: 50%;
        transform: translate(-50%,0);
    }
}