.checkout_container {
    margin-top: 30px;
}

.title {
    margin: 0;
}

.title:after {
    content: '';
    height: 1px;
    width: 100%;
    background: #E6E6E5;
    margin: 8px 0 10px;
    display: block;
}

.info {
    margin: 0 0 32px;
}