.product_information {
    margin-bottom: 40px;
}

.video {
    width: 100%;
    aspect-ratio: 640 / 360;
}

@media (max-width:768px) {
    .product_information {
        flex-direction: column-reverse;
    }

    .product_information > div {
        margin-top: 48px;
    }
}