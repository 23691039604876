.category_button {
    left: 50%;
    transform: translate(-50%,0);
    position: relative;
    margin-top: 25px;
}


@media (max-width: 768px) {
    .category_button {
        margin-top: 5px;
        padding: 0 !important;
        border: 0;
        text-decoration: underline;
    }

    .category_button:hover {
        background: transparent !important;
        color: inherit !important;
    }
}