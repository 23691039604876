.root {
    position: relative
}

.modal {
    position: fixed;
    display: block;
    left: -1300%;
    top: 0;
    width: 100%;
    height: 100%;
    background: white;
}

.open.modal {
    z-index: 999;
    left: 0;
}

.close {
    background:
    linear-gradient(#000,#000),
    linear-gradient(#000,#000);
    background-position:center;
    background-size: 50% 1px,1px 50%;
    background-repeat:no-repeat;
    display: inline-block;
    width: 50px !important;
    height: 50px;
    transform: rotate(45deg);
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
    cursor: pointer;
}

.plus {
    background:
    linear-gradient(#000,#000),
    linear-gradient(#000,#000);
    background-position:center;
    background-size: 50% 1px,1px 50%;
    background-repeat:no-repeat;
    display: inline-block;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.min {
    background:
    linear-gradient(#000,#000),
    linear-gradient(#000,#000);
    background-position:center;
    background-size: 50% 1px,0 50%;
    background-repeat:no-repeat;
    display: inline-block;
    width: 50px;
    height: 50px;
    cursor: pointer;
}

.zoom_image {
    width: 80%;
    margin: 0 auto;
}

.zoom_container {
    height: 100%;
}

.tools {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    opacity: 0.7;
    position:absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
}

@media (min-width: 768px) {
    .root {
        margin: 34px 0;
    }
}