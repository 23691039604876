
.sidebar h2 {
    width: 100%;
    border-bottom: 1px solid #E6E6E5;
    padding: 0px 0px 8px 0px;
}

.address {
    margin-bottom: 40px;
}

.iconline {
    display: flex;
    align-items: center;
    height: 20px;
    margin-top: 16px; 
}

.iconline .icon {
    margin: 0 8px;
}

.image {
    margin: 0px -16px 25px -16px;
    width: 100vw;
}

.image img { width: 100vw; }

.timelist {
    margin-bottom: 40px;
}

.description {
    margin-bottom: 25px;
}

@media (min-width: 640px) { 

    .sidebar h2 {
        padding: 0px 8px 8px 8px;
    }

    .address {
        padding: 0px 8px;
    }
    
    .address h3{
        margin-bottom: 8px;
    }

    .timelist {
        padding: 0px 8px;
    }

    .image {
        margin: 0px 0px 25px 0px;
        width: 100%;
    }

    .image img { width: 100%; }
}