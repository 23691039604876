
.locations {
    
}

.map {
    border-radius: 5px;
    overflow: hidden;
    height: 300px;
}

@media (min-width: 640px) {
    .map {
        height: 100%;
    }
    
}