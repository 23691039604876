.menu > a, .menu .item { 
    position: relative; 
    display: inline-flex;
    align-items: center;
    height: 40px;
    color: #ffffff; 
    margin-right: 32px;
    text-decoration: none;
    font-size:1.3rem;
    border-bottom:3px var(--color-primary-darken) solid;
    border-top:3px var(--color-primary-darken) solid;
    cursor: pointer;
}

.menu > a:last-child { margin-right: 0px; }

.menu > a:global(.active) {
    border-bottom:3px #B7A983 solid;
}

.menu .item .submenu { display: none; }

.menu > a:hover, .menu .item { color: #ffffff; border-bottom:3px #B7A983 solid; }
.menu .item:hover .submenu { display: block; }

.menu .item .chevron {
    display: inline-flex;
    margin-left: 10px;
    transition: transform .2s;
}
.menu .item:hover .chevron { transform: rotate(180deg); }

.submenu {
    position: absolute;
    top: 37px;
    min-width: 240px;
    min-height: 300px;
    background: #ffffff; 
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
    border-radius: 0px 0px 5px 5px;
    padding: 0 16px;
    z-index: 10;
}

.submenu a { 
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    padding: 12px 8px;
    width: 100%;
    border-bottom: 1px #E6E6E5 solid;
    color: #1A1B1D;
}

.submenu a:last-child {
    border-bottom: none;
}
