.root {
    position: relative;
    display: flex;
    align-items: center;
}

.minicart_icon {
    cursor: pointer;
}

.count {
    position: absolute;
    border-radius: 50%;
    background: #F0AD4E;
    color: #FFFFFFFF;
    font-size: 1.1rem;
    line-height: 1.32rem;
    width: 19px;
    height: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 8px;
    right: -6px;
}