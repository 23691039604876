.root {
    display: flex;
}

.select {
    width: 100%;
    margin: 0;
    padding: 8px 10px;
    border: 1px solid #E6E6E5;
    background: #fff;
    outline: none;
    border-radius: 5px;
    box-shadow: none;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.select:not([multiple]) {
	background-image: url('data:image/svg+xml;utf8,<svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M0.645978 0.645993C0.692424 0.59943 0.7476 0.562487 0.808345 0.537281C0.86909 0.512074 0.934211 0.4991 0.999978 0.4991C1.06575 0.4991 1.13087 0.512074 1.19161 0.537281C1.25236 0.562487 1.30753 0.59943 1.35398 0.645993L6.99998 6.29299L12.646 0.645993C12.6925 0.599505 12.7477 0.562629 12.8084 0.53747C12.8691 0.512311 12.9342 0.499362 13 0.499362C13.0657 0.499362 13.1308 0.512311 13.1916 0.53747C13.2523 0.562629 13.3075 0.599505 13.354 0.645993C13.4005 0.692481 13.4373 0.74767 13.4625 0.80841C13.4877 0.869149 13.5006 0.934249 13.5006 0.999993C13.5006 1.06574 13.4877 1.13084 13.4625 1.19158C13.4373 1.25232 13.4005 1.30751 13.354 1.35399L7.35398 7.35399C7.30753 7.40056 7.25236 7.4375 7.19161 7.46271C7.13087 7.48791 7.06575 7.50089 6.99998 7.50089C6.93421 7.50089 6.86909 7.48791 6.80834 7.46271C6.7476 7.4375 6.69242 7.40056 6.64598 7.35399L0.645978 1.35399C0.599415 1.30755 0.562472 1.25237 0.537266 1.19163C0.512059 1.13088 0.499084 1.06576 0.499084 0.999993C0.499084 0.934226 0.512059 0.869105 0.537266 0.80836C0.562472 0.747614 0.599415 0.692439 0.645978 0.645993Z" fill="black"/></svg>');
	background-position: right 12px center;
    background-repeat: no-repeat;
	background-size: 14px 8px;
	padding-right: 25px;
}


@media (min-width: 768px) {

    .root {
        width: 160px;
        margin-left: auto;
    }
}