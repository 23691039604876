.title {
    margin: 0;
}

.title:after {
    content: '';
    height: 1px;
    width: 100%;
    background: #E6E6E5;
    margin: 8px 0 10px;
    display: block;
}

.content {
    padding-right: 40px;
}

.content h1, .content h2, .content h3, .content h4 {
    margin: 0 0 6px;
}