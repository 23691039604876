
.field {
    position: relative; 
    display: flex;
    align-items: center;
}

.label {
    display: inline-block;
    font-size: 1.4rem;
    line-height: 2.0rem;
    width: 125px;
    min-width: 125px;
    margin-right: 1.6rem;
    text-align: right;
}

.input {
    width: calc(100% - 120px);
    margin: 0;
    padding: 10px 16px;
    background: #F6F4F0;;
    color: #949290;
    border: 0;
    outline: none;
    border-radius: 5px;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.input.no_label {
    width: 100%;
}

.input_error,
:global(form.validated) .input:invalid {
    border: 1px solid #FF595C;
    background: #FFE8DD;
}

.asterisk { color: #EE0128; }

@media (max-width: 768px) {
    .field {
        flex-direction: column;
        align-items: flex-start;
    }

    .input {
        width: 100%;
    }

    .label {
        width: 100%;
        text-align: left;
        padding: 0;
        margin-bottom: 5px;
    }

    .input.no_label {
        margin-top: 25px;
    }
}