:global(.button),
.button {
	appearance: none;
	-webkit-appearance: none;
    -moz-appearance: none;
	font-weight: 500;
	font-size: 1.3rem;
	line-height: 1.526rem;
	text-align: center;
	padding: 0 16px;
	height: 40px;
	border-radius: 5px;
	border: 1px solid;
	border-bottom-width: 2px;
	cursor: pointer;
	background: var(--color-primary-darken);
	border-color: #390033;
	color: #FFFFFF;
    text-decoration: none;
    display: table;
}

:global(.button:hover),
:global(.button:active),
.button:hover,
.button:active {
	background: var(--color-primary);
    color: #FFFFFF;
}

:global(.button.secondary),
.button.secondary {
	background: var(--color-secondary-darken);
	border-color: #C89041;
	color: #FFFFFF;
}

.button > * {
	display: table-cell;
	vertical-align: middle;
}

.button > *:not(:last-child) {
	padding-right: 5px;
    box-sizing: content-box;
}

:global(.button.secondary:hover),
:global(.button.secondary:active),
.button.secondary:hover,
.button.secondary:active {
	background: var(--color-secondary);
}

:global(.button.alt-secondary),
.button.alt-secondary {
	background: #B4B4B4;
	border-color: #A4A4A4;
	color: #FFFFFF;
}

:global(.button.alt-secondary:hover),
:global(.button.alt-secondary:active),
.button.alt-secondary:hover,
.button.alt-secondary:active {
	background: #c2c2c2;
}

:global(.button.transparent),
.button.transparent {
	background: transparent;
	border-color: #390033;
	color: var(--color-primary-darken);
	border-width: 1px;
	padding: 10px 16px 9px;
}

:global(.button.transparent:hover),
:global(.button.transparent:active),
.button.transparent:hover,
.button.transparent:active {
	background: var(--color-primary-darken);
	color: #FFFFFF;
}

:global(.button.success),
.button.success {
	background: #23915e;
	border-color: #1c744b;
	color: #FFFFFF;
}
:global(.button.success:hover),
:global(.button.success:active),
.button.success:hover,
.button.success:active {
	background: #1c744b;
}