.root {
    position: relative;
}

.image {
    border-radius: 5px;
    overflow: hidden;
}

.image img {
    width: auto;
    max-width: inherit;
    height: 100%;
}

.name {
    font-size: 1.4rem;
    color: var(--color-primary);
    text-align: center;
    padding: 16px 0 8px 0;
}

.tile_link {
    text-decoration: none;
}

@media (min-width:768px) {
    .name { font-size: 1.6rem; }
}
